import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_SELECTED_COUNTRIES } from '../../constants/defaults';

export interface SupportedCountriesState {
  supportedCountries: string[];
  error: string;
}

const initialState: SupportedCountriesState = {
 supportedCountries: DEFAULT_SELECTED_COUNTRIES,
  error: '',
};

export const supportedCountriesSlice = createSlice({
  name: 'supportedCountries',
  initialState,
  reducers: {
    setSupportedCountries: (state, action: PayloadAction<string[]>) => {
      state.supportedCountries = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setError, setSupportedCountries } = supportedCountriesSlice.actions;

export default supportedCountriesSlice.reducer;
