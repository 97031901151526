import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useEnvContext } from 'utils/EnvProvider';
import { getAdminPortalSharedBff } from 'services/getAdminPortalSharedBff';
import { setError, setSupportedCountries } from 'store/slices/supportedCountries.slice';

const useUserMetadata = () => {
  const dispatch = useDispatch();
  const { api } = getAdminPortalSharedBff();
  const { REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF } = useEnvContext();

  const getUserMetadata = useCallback(async () => {
    const token = localStorage.getItem('authHeader');

    try {
      const response = await api.get('/user-metadata', {
        headers: {
          'Authorization': token,
          requestTraceId: REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF,
        }
      })
      const { supportedCountries } = response?.data || [];

      dispatch(setSupportedCountries(supportedCountries));
    } catch (error) {
      const messageError = `Error fetching user metadata`;
      console.error(messageError);
      dispatch(setError(messageError));
    };

  }, [api]);

  return { getUserMetadata };
}

export default useUserMetadata;
