import { Toast } from '@hexa-ui/components';
import { useQueryClient, useMutation as useReactQueryMutation } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { IMutation, Variables } from './useMutationApollo.types';
const graphQlClient = (url: string, authorization: string, authorizationHub: string) => {
  return new GraphQLClient(url, {
    headers: {
      authorization: authorization,
      authorizationHub: authorizationHub,
    },
  });
};
export function useMutationApollo({ key, graph, authorization, authorizationHub }: IMutation) {
  const queryClient = useQueryClient();
  return useReactQueryMutation({
    mutationFn: async (params: { query: string; variables?: Variables }) => {
      return await graphQlClient(graph, authorization, authorizationHub).request(
        params.query,
        params.variables
      );
    },
    onSuccess: () => {
      Toast;
      key && queryClient.invalidateQueries(key);
    },
    onError: (e) => {
      throw Error(`Something went wrong with the request ${e}`);
    },
  });
}
