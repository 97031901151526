import { useState } from 'react';

import { CreateScheduleProps, DownloadScheduleProps } from 'pages/Reports/types/Reports.types';

export function useModalContext() {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [isScheduledModalOpen, setScheduledModalOpen] = useState(false);

  const [handleCreateSchedule, setHandleCreateSchedule] = useState<
    (values: CreateScheduleProps) => void
  >(() => {});

  const [handleSubmitDownload, setHandleSubmitDownload] = useState<
    (values: DownloadScheduleProps) => void
  >(() => {});

  return {
    downloadModal: {
      isOpen: isDownloadModalOpen,
      setOpen: setIsDownloadModalOpen,
      handleSubmitDownload,
      setHandleSubmitDownload,
    },
    scheduleModal: {
      isOpen: isScheduleModalOpen,
      setOpen: setScheduleModalOpen,
      handleCreateSchedule,
      setHandleCreateSchedule,
    },
    scheduledModal: {
      isOpen: isScheduledModalOpen,
      setOpen: setScheduledModalOpen,
    },
  };
}
