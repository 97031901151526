import { useQueryClient, useMutation as useReactQueryMutation } from '@tanstack/react-query';
import { AxiosRequestHeaders, Method } from 'axios';

import { RateLimitedAxiosInstance } from 'axios-rate-limit';
import { CreateApiInstance } from '../../../services/createApiInstance';
import { IMutationSettings } from './usePost.type';

export const request =
  (url: string, method: Method, headers?: AxiosRequestHeaders, api?: RateLimitedAxiosInstance) =>
  async (data: unknown) => {
    const { data: response } = await api.request({ url, headers, data: data, method });

    return response;
  };

export function useRestMutation<Data = unknown, Error = unknown, Params = unknown>(
  endpoint: string,
  settings?: IMutationSettings<Data, Error>
) {
  const queryClient = useQueryClient();

  const { api } = CreateApiInstance();

  const requestQuery = useReactQueryMutation<Data, Error, Params, unknown>(
    request(endpoint, settings?.method ?? 'POST', settings?.headers, api),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['']);
      },
      onError: () => {
        throw Error('Something went wrong with the request');
      },
    }
  );

  return requestQuery;
}
