export const columnsHideShow = [
  {
    key: 'PAYMENT_ID',
    label: 'Payment ID',
  },
  {
    key: 'ORDER_NUMBER',
    label: 'Order ID',
  },
  {
    key: 'BUYER_ID',
    label: 'Buyer ID',
  },
  {
    key: 'INVOICE_DELIVERY_CENTER_ID',
    label: 'Seller ID',
  },
  {
    key: 'ACCOUNT_ID',
    label: 'Account ID',
  },
  {
    key: 'INVOICE_NUMBER',
    label: 'Invoice Number',
  },
  {
    key: 'PAYMENT_VALUE',
    label: 'Payment Value',
  },
  {
    key: 'PAYMENT_STATUS',
    label: 'Payment Status',
  },
  {
    key: 'PAYMENT_METHOD',
    label: 'Payment Method',
  },
  {
    key: 'PAYMENT_METHOD_CODE',
    label: 'Payment Method Code',
  },
  {
    key: 'ORDER_DATE',
    label: 'Creation Date and Time',
  },
  {
    key: 'ORDER_STATUS',
    label: 'Order Status',
  },
  {
    key: 'INVOICE_VALUE',
    label: 'Invoice Value',
  },
  {
    key: 'INVOICE_STATUS',
    label: 'Invoice Status',
  },
  {
    key: 'PAYMENT_CODE',
    label: 'Barcode ID',
  },
  {
    key: 'PAYMENT_DATE',
    label: 'Payment Date and Time',
  },
  {
    key: 'STORE_NAME',
    label: 'Convenience Store',
  },
];
