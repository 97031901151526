import { useQueryClient, useMutation as useReactQueryMutation } from '@tanstack/react-query';

import { GraphQLClient } from 'graphql-request';
import { IMutation } from '../useFetch/useFetch.types';

const graphQlClient = (url: string, authorization: string, authorizationHub: string) => {
  return new GraphQLClient(url, {
    headers: {
      authorization: authorization,
      authorizationHub: authorizationHub,
    },
  });
};

export function useMutation({
  key,
  graph,
  query,
  variables,
  authorization,
  authorizationHub,
}: IMutation) {
  const queryClient = useQueryClient();
  return useReactQueryMutation({
    mutationFn: async () => {
      return await graphQlClient(graph, authorization, authorizationHub).request(query, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },

    onError: () => {
      throw Error('Something went wrong with the request');
    },
  });
}
