import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from 'components/Loading/Loading';

export const BASE_PATH = '/bees-gateway-web';

const Base = lazy(() => import('../components/AuthLoadingWrapper'));
const Reports = lazy(() => import('../pages/Reports'));

export const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path={`${BASE_PATH}`} element={<Base />}>
        <Route index element={<Reports />} />
      </Route>
    </Routes>
  </Suspense>
);

export default AppRoutes;
