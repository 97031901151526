export const labelCountry = [
  {
    name: 'Colombia',
    value: 'CO',
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
  },
  {
    name: 'Mexico',
    value: 'MX',
  },
];

export const labelInvoiceStatus = [
  {
    idName: 'invoiceStatusCancelled',
    label: 'Cancelled',
    value: 'CANCELLED',
  },
  {
    idName: 'invoiceStatusClosed',
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    idName: 'invoiceStatusDelivered',
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    idName: 'invoiceStatusOpen',
    label: 'Open',
    value: 'OPEN',
  },
  {
    idName: 'invoiceStatusPending',
    label: 'Pending',
    value: 'PENDING',
  },
];

export const labelOrderStatus = [
  {
    idName: 'orderStatusAvailable',
    label: 'Available',
    value: 'AVAILABLE',
  },
  {
    idName: 'orderStatusCancelledOrder',
    label: 'Cancelled',
    value: 'CANCELLED',
  },
  {
    idName: 'orderStatusConfirmed',
    label: 'Confirmed',
    value: 'CONFIRMED',
  },
  {
    idName: 'orderStatusDeliveredOrder',
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    idName: 'orderStatusDenied',
    label: 'Denied',
    value: 'DENIED',
  },
  {
    idName: 'orderStatusExpired',
    label: 'Expired',
    value: 'EXPIRED',
  },
  {
    idName: 'orderStatusInvoiced',
    label: 'Invoiced',
    value: 'INVOICED',
  },
  {
    idName: 'orderStatusInTransit',
    label: 'In transit',
    value: 'IN_TRANSIT',
  },
  {
    idName: 'orderStatusModified',
    label: 'Modified',
    value: 'MODIFIED',
  },
  {
    idName: 'orderStatusPartialDelivery',
    label: 'Partial delivery',
    value: 'PARTIAL_DELIVERY',
  },
  {
    idName: 'orderStatusPending',
    label: 'Pending',
    value: 'PENDING',
  },
  {
    idName: 'orderStatusPendingCancelation',
    label: 'Pending cancelation',
    value: 'PENDING_CANCELLATION',
  },
  {
    idName: 'orderStatusPendingPayment',
    label: 'Pending Payment',
    value: 'PENDING_PAYMENT',
  },
  {
    idName: 'orderStatusPlaced',
    label: 'Placed',
    value: 'PLACED',
  },
];

export const labelPaymentMethodStatus = [
  {
    idName: 'paymentStatusExpired',
    label: 'Expired',
    value: 'EXPIRED',
  },
  {
    idName: 'paymentStatusFailed',
    label: 'Failed',
    value: 'FAILED',
  },
  {
    idName: 'paymentStatusPaid',
    label: 'Paid',
    value: 'PAID',
  },
];
