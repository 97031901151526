import { combineReducers } from '@reduxjs/toolkit';
import reportsSlice from './reports.slice';
import supportedCountriesSlice from './supportedCountries.slice';

const reducers = combineReducers({
  report: reportsSlice,
  supportedCountries: supportedCountriesSlice,
});

export default reducers;
