import * as React from 'react';

export interface LanguageContextProps {
  language: string;
  setLanguage: (value: string) => void;
}

const LanguageContext = React.createContext<LanguageContextProps | undefined>(undefined);

export function useLanguageContext(): LanguageContextProps | undefined {
  return React.useContext(LanguageContext);
}

export default LanguageContext;
