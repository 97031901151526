import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_COUNTRY } from '../../constants/defaults';
import { columnsTable } from '../../pages/Reports/utils';

export interface ReportsState {
  selectedCountry: string;
  fieldsToExport: string[];
}

const initialState: ReportsState = {
  selectedCountry: DEFAULT_COUNTRY,
  fieldsToExport: columnsTable.map((column) => column.key),
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    SET_COUNTRY: (state, action: PayloadAction<string>) => {
      state.selectedCountry = action.payload;
    },
    RESET_COUNTRY: (state) => {
      state.selectedCountry = DEFAULT_COUNTRY;
    },
    SET_FIELDS_TO_EXPORT: (state, action: PayloadAction<string[]>) => {
      state.fieldsToExport = action.payload;
    },
  },
});

export const { RESET_COUNTRY, SET_COUNTRY, SET_FIELDS_TO_EXPORT } = reportsSlice.actions;

export default reportsSlice.reducer;
