import { createContext } from 'react';

import { AuthContextProps, AuthProviderProps } from './AuthContext.type';
import { useAuthContext } from './useAuthContext';

export const AuthContext = createContext({} as AuthContextProps);

export function AuthProvider({ children }: AuthProviderProps) {
  const { authorization } = useAuthContext();

  return (
    <AuthContext.Provider
      value={{
        authorization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
