import { LoadingBuzz } from '@hexa-ui/components';

import { Container } from './Loading.styles';

const Loading = (): JSX.Element => {
  return (
    <Container>
      <LoadingBuzz size="xlarge" />
    </Container>
  );
};

export default Loading;
