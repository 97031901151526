import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StyledGrid = styled(Grid.Container, {
  paddingTop: '$4',
  rowGap: '1rem',
  '@desktopSmall': {
    paddingTop: 0,
  },
});
