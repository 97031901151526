import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import { useEnvContext } from 'utils/EnvProvider';

export const CreateApiInstance = () => {
  const { REACT_APP_AUTH_API } = useEnvContext();

  const api = rateLimit(
    axios.create({
      baseURL: REACT_APP_AUTH_API,
    }),
    { maxRequests: 1 }
  );

  return { api };
};
