import axios from 'axios';
import { useEnvContext } from 'utils/EnvProvider';

export const getAdminPortalSharedBff = () => {
  const { REACT_USER_METADATA_API } = useEnvContext();

  const api = axios.create({
    baseURL: REACT_USER_METADATA_API,
  });

  return { api };
};
