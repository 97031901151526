import { Grid } from '@hexa-ui/components';
import { Megaphone } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { Router } from '../../Router';

import { StyledGrid } from './AppBarConfigProvider.styles';

const AppBarConfigProvider = (): JSX.Element => {
  // const Grid2Icon = () => <Grid2 />;
  const MegaphoneIcon = () => <Megaphone />;

  // const intl = useIntl();

  useSidebar({
    items: [
      {
        id: '1',
        title: 'Reports', // intl.formatMessage({ id: 'sidebar.report' })
        icon: MegaphoneIcon,
        path: '/bees-gateway-web',
      },
    ],
    utils: [],
  });

  return (
    <StyledGrid type="fluid" sidebar>
      <Router />
      <Grid.Item xl={9} lg={9} md={9} />
      <Grid.Item xl={3} lg={3} md={3}>
        {/* <section style={{ margin: '5rem 0', width: '100%' }}>{<ThemeSelection />}</section> */}
      </Grid.Item>
    </StyledGrid>
  );
};

export default AppBarConfigProvider;
