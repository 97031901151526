import MessageMap from 'translations/i18n.d';

const ptBr: MessageMap = {
  downloadModal: {
    titleHeader: 'Relatório por email',
    description: 'O arquivo contendo os dados filtrados será enviado por email.',
    fieldEmail: 'Seu email',
    fieldFileFormat: 'Formato',
    sendButton: 'Enviar',
    cancelButton: 'Voltar',
  },
  scheduleModal: {
    titleHeader: 'Agendar envio',
    description: 'Receba o arquivo em seu email',
    fieldFileFormat: 'Formato',
    fieldFrequency: 'Frequência',
    subFieldFrequency: 'Recorre toda semana em:',
    fieldDelivery: 'Horário de envio',
    fieldEmail: 'Email',
    sendButton: 'Agendar',
    cancelButton: 'Cancelar',
  },
};
export default ptBr;
