import QueryString from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useRestMutation } from 'core/hooks';
import { useEnvContext } from 'utils/EnvProvider';
import { AuthorizationHubResponse } from './AuthContext.type';
import { AxiosRequestHeaders } from 'axios';

export function useAuthContext() {
  const [authorization, setAuthorization] = useState<string>();
  const { REACT_APP_CLIENT_ID_KONG, REACT_APP_GRANT_TYPE_KONG, REACT_CLIENT_SECRET_KONG } =
    useEnvContext();

  const { mutate } = useRestMutation<AuthorizationHubResponse>(`/gateway-web-bff/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    } as AxiosRequestHeaders,
  });

  const loadAuthorizationHub = useCallback(() => {
    const body = {
      grant_type: REACT_APP_GRANT_TYPE_KONG,
      client_id: REACT_APP_CLIENT_ID_KONG,
      client_secret: REACT_CLIENT_SECRET_KONG,
    };

    return mutate(QueryString.stringify(body), {
      onSuccess: (response) => {
        setAuthorization(`${response.token_type} ${response.access_token}`);
      },
      onError: (error) => {
        return error;
      },
    });
  }, [REACT_APP_CLIENT_ID_KONG, REACT_APP_GRANT_TYPE_KONG, REACT_CLIENT_SECRET_KONG, mutate]);

  useEffect(() => {
    loadAuthorizationHub();
  }, [loadAuthorizationHub]);

  return { authorization };
}
