import { ColumnData } from '../types/Reports.types';
import { parsePaymentValueColumn } from './parseColumn';

export const columnsTable: ColumnData[] = [
  {
    Header: 'Payment ID',
    accessor: 'paymentId',
    disableSortBy: true,
    key: 'PAYMENT_ID',
  },
  {
    Header: 'Order ID',
    accessor: 'orderNumber',
    disableSortBy: true,
    key: 'ORDER_NUMBER',
  },
  {
    Header: 'Buyer ID',
    accessor: 'buyerId',
    disableSortBy: true,
    key: 'BUYER_ID',
  },
  {
    Header: 'Seller ID',
    accessor: 'sellerId',
    disableSortBy: true,
    key: 'INVOICE_DELIVERY_CENTER_ID',
  },
  {
    Header: 'Account ID',
    accessor: 'accountId',
    disableSortBy: true,
    key: 'ACCOUNT_ID',
  },
  {
    Header: 'Invoice Number',
    accessor: 'invoiceNumber',
    disableSortBy: true,
    key: 'INVOICE_NUMBER',
  },
  {
    Header: 'Payment Value',
    accessor: 'paymentValue',
    disableSortBy: true,
    key: 'PAYMENT_VALUE',
    Cell: parsePaymentValueColumn,
  },
  {
    Header: 'Payment Status',
    accessor: 'paymentStatus',
    disableSortBy: true,
    key: 'PAYMENT_STATUS',
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    disableSortBy: true,
    key: 'PAYMENT_METHOD',
  },
  {
    Header: 'Payment Method Code',
    accessor: 'paymentMethodCode',
    disableSortBy: true,
    key: 'PAYMENT_METHOD_CODE',
  },
  {
    Header: 'Creation Date and Time',
    accessor: 'orderDate',
    disableSortBy: true,
    key: 'ORDER_DATE',
  },
  {
    Header: 'Order Status',
    accessor: 'orderStatus',
    disableSortBy: true,
    key: 'ORDER_STATUS',
  },
  {
    Header: 'Invoice Value',
    accessor: 'invoiceValue',
    disableSortBy: true,
    key: 'INVOICE_VALUE',
  },
  {
    Header: 'Invoice Status',
    accessor: 'invoiceStatus',
    disableSortBy: true,
    key: 'INVOICE_STATUS',
  },
  {
    Header: 'Barcode ID',
    accessor: 'barCodeGenerated',
    disableSortBy: true,
    key: 'PAYMENT_CODE',
  },
  {
    Header: 'Payment Date and Time',
    accessor: 'paymentDate',
    disableSortBy: true,
    key: 'PAYMENT_DATE',
  },
  {
    Header: 'Convenience Store',
    accessor: 'storeName',
    disableSortBy: true,
    key: 'STORE_NAME',
  },
];
