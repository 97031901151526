import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DocumentTitle, PropsPerClusterProvider, useHubTheme } from 'admin-portal-shared-services';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { AuthProvider } from 'contexts';
import IntlProvider from 'translations/provider';
import { store } from 'store';
import AppBarConfigProvider from 'utils/AppBarConfigProvider';
import { EnvConfig, EnvProvider } from 'utils/EnvProvider';
import { SetThemeProvider } from 'utils/Theme';

const queryClient = new QueryClient();

export default function App(props: EnvConfig) {
  const [hubTheme, setHubTheme] = useState<ThemeProviderType['theme']>('customer');
  useHubTheme(hubTheme);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EnvProvider env={props}>
          <PropsPerClusterProvider propValues={props}>
            <ThemeProvider theme={hubTheme}>
              <SetThemeProvider setTheme={setHubTheme}>
                <DocumentTitle>Bees Pay</DocumentTitle>
                <AuthProvider>
                  <Provider store={store}>
                    <IntlProvider>
                      <AppBarConfigProvider />
                    </IntlProvider>
                  </Provider>
                </AuthProvider>
              </SetThemeProvider>
            </ThemeProvider>
          </PropsPerClusterProvider>
        </EnvProvider>
      </ErrorBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
