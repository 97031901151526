import { createContext, useContext } from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string;
  REACT_APP_API_BFF: string;
  REACT_APP_HOME_PATH: string;
  REACT_APP_AUTH_TOKEN: string;
  REACT_APP_GRANT_TYPE_KONG: string;
  REACT_APP_CLIENT_ID_KONG: string;
  REACT_CLIENT_SECRET_KONG: string;
  REACT_APP_AUTH_API: string;
  REACT_USER_METADATA_API: string;
  REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF: string;
  REACT_REQUEST_TRACE_ID_APP_API_BFF: string;
};

export const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  REACT_APP_API_BFF: '',
  REACT_APP_HOME_PATH: '',
  REACT_APP_AUTH_TOKEN: '',
  REACT_APP_GRANT_TYPE_KONG: '',
  REACT_APP_CLIENT_ID_KONG: '',
  REACT_CLIENT_SECRET_KONG: '',
  REACT_APP_AUTH_API: '',
  REACT_USER_METADATA_API: '',
  REACT_REQUEST_TRACE_ID_ADMIN_PORTAL_SHARED_BFF: '',
  REACT_REQUEST_TRACE_ID_APP_API_BFF: '',
};

export const EnvContext = createContext(defaultInitialValue);

export const useEnvContext = () => useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
