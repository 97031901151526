import { createContext } from 'react';
import { useModalContext } from './useModalContext';
import { ModalContextProps, ModalProviderProps } from './ModalContext.type';

export const ModalContext = createContext({} as ModalContextProps);

export function ModalProvider({ children }: ModalProviderProps) {
  const { downloadModal, scheduleModal, scheduledModal } = useModalContext();

  return (
    <ModalContext.Provider
      value={{
        downloadModal,
        scheduleModal,
        scheduledModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
